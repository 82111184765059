import { isUndefined, waitUntil } from '@iheartradio/web.utilities';
import { useEffect } from 'react';

import { useConfig } from '~app/contexts/config';
import { useUser } from '~app/contexts/user';
import { useRootLoaderData } from '~app/hooks/use-root-loader-data';

import { useAnalytics } from '../create-analytics';
import { useBraze } from './braze-sdk';

export function Braze() {
  const { appVersion } = useRootLoaderData();
  const analytics = useAnalytics();
  const braze = useBraze();
  const config = useConfig();
  const user = useUser();

  useEffect(() => {
    if (!user) return;

    braze.initialize({
      apiKey: config.sdks.braze?.apiKey,
      appVersion,
      user: {
        profileId: user.profileId.toString(),
        email: user.email,
        isAnonymous: user.isAnonymous,
        genreIsDefault: false,
        genreSelected: [],
        isTrialEligible: user.subscription?.isTrialEligible ?? false,
        privacyOptOut: user.privacy?.hasOptedOut ?? false,
        subscriptionTier: user.subscription?.type ?? 'NONE',
      },
      baseUrl: config.sdks.braze?.baseUrl || '',
      allowUserSuppliedJavascript: true,
      enableLogging: config.sdks.braze?.enableLogging || true,
      manageServiceWorkerExternally: !config.sdks.braze?.enableLogging,
      minimumIntervalBetweenTriggerActionsInSeconds: 1,
      sessionTimeoutInSeconds: config.sdks.braze?.enableLogging ? 1 : 1800,
      serviceWorkerLocation: '/serviceWorker.js',
    });

    // open braze session after further configuration.
    if (isUndefined(window.braze)) {
      const unsubscribe = analytics.subscribe({
        async initialize() {
          await waitUntil(() => !!window.braze);
          // NOTE include necessary logic for handling inApp messages and analytics tracking for events.
          // braze.subscribeToInAppMessage(inAppMessage => { });

          window.braze?.openSession();
          window.braze?.logCustomEvent('prime-for-push');
        },

        async track() {
          // track events
        },
      });

      return unsubscribe;
    }

    return () => {};
  }, []);

  return null;
}
