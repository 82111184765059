import '@iheartradio/web.accomplice/globals';

import {
  type Theme,
  core,
  FullScreenProvider,
  ThemeContext,
  ToastProvider,
} from '@iheartradio/web.companion';
import companionNormalizeCSSUrl from '@iheartradio/web.companion/normalize.css?url';
import companionResetCSSUrl from '@iheartradio/web.companion/reset.css?url';
import { SubscriptionTypeEnum } from '@iheartradio/web.config';
import {
  type DocumentSharedProps,
  ClientHintCheck,
  getHints,
} from '@iheartradio/web.remix-shared';
import {
  METADATA_APP_NAME,
  METADATA_DOMAIN,
  METADATA_OPENGRAPH_TYPES,
  METADATA_TWITTER_CARDS,
  METADATA_TWITTER_HANDLE,
  setBasicMetadata,
} from '@iheartradio/web.remix-shared';
import {
  useAppOpenClose,
  useTheme,
  useTrackVisibilityChange,
} from '@iheartradio/web.remix-shared/react';
import { getServerTiming } from '@iheartradio/web.server-timing';
import {
  CCPAUserPrivacy,
  isNotBlank,
  isNotNil,
  toURL,
} from '@iheartradio/web.utilities';
import { createWebStorage } from '@iheartradio/web.utilities/create-storage';
import { toBoolean } from '@iheartradio/web.utilities/transformers';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from '@remix-run/react';
import {
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
  type ServerRuntimeMetaFunction,
  isSession,
  json,
  redirect,
} from '@remix-run/server-runtime';
import { isbot } from 'isbot';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  type DisplayAdsScriptsConfig,
  type HeaderBiddingConfig,
  DisplayAdsScripts,
  GoogleAdsProvider,
} from '~app/ads/display/ads';
import { PlaybackAdsScripts } from '~app/ads/playback';
import { Analytics } from '~app/analytics/analytics';
import { analytics, useAnalytics } from '~app/analytics/create-analytics';
import { amp } from '~app/api/amp-client';
import { AppErrorBoundary } from '~app/components/error/app-error-boundary';
import { Layout } from '~app/components/layout';
import { ConfigProvider } from '~app/contexts/config';
import { UserContext } from '~app/contexts/user';
import { useRootLoaderData } from '~app/hooks/use-root-loader-data';
import {
  expireCookieIn24hours,
  expireCookieIn365days,
  MarketSession,
  ShowDialogCookieJar,
  ThemeCookie,
  TryOutListenCookieJar,
} from '~app/lib/remix-shared-node.server';
import {
  getDomainUrl,
  getForwardedOrigin,
  getGeolocationForRequest,
  getOriginReferer,
  getThemeFromRequest,
} from '~app/lib/remix-shared-server.server';

import { useRegGateEvent } from './analytics/use-reg-gate';
import { getMarketsById, getOrderedMarkets } from './api/markets';
import { AdsTargetingProvider } from './contexts/ads';
import ClientStyleContext from './contexts/client-style';
import { useGetPageName } from './hooks/use-get-page-name';
import { useVisitCount } from './hooks/use-visit-count';
import { PlaybackProvider } from './playback/playback';
import {
  METADATA_APPLE_TOUCH_ICON,
  METADATA_DEFAULT_IMAGE,
  METADATA_GLOBAL_DESCRIPTION,
  METADATA_GLOBAL_KEYWORDS,
  METADATA_GLOBAL_TITLE,
} from './utilities/constants';
import { hydrateContext } from './utilities/context.server';
import { DynamicLinks } from './utilities/dynamic-links';
import { getMarketForRequest } from './utilities/get-market-for-request.server';
import { getBrowserLanguage } from './utilities/utilities';

export type RootLoader = typeof loader;

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return loaderHeaders;
};

export const links: LinksFunction = () => [
  // Font loading
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap',
  },
  { rel: 'stylesheet', href: companionNormalizeCSSUrl },
  { rel: 'stylesheet', href: companionResetCSSUrl },
];

async function clearSessionHandler(request: Request) {
  const url = toURL(request.url);

  if (url.searchParams.get('clear-market')?.toLowerCase() === 'true') {
    url.searchParams.delete('market');
    url.searchParams.delete('clear-market');
    const headers = new Headers(request.headers);

    // Market Session
    {
      const marketSession = await MarketSession.getSession(
        request.headers.get('Cookie'),
      );

      if (isSession(marketSession)) {
        headers.append(
          'Set-Cookie',
          await MarketSession.destroySession(marketSession),
        );
      }
    }

    const originRedirectUrl = toURL(url.pathname, getForwardedOrigin(request));

    return redirect(originRedirectUrl.toString(), { headers });
  }
}

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const { time, getHeaderField } = getServerTiming({ prefix: 'root' });

  await time('clearSession', () => clearSessionHandler(request));

  const { CONFIG_ENV, npm_package_version, SHORT_COMMIT } = process.env;

  const { authEvent, user, config, locale, ampClientConfig } =
    hydrateContext(context);
  const url = new URL(request.url);

  const tryListenApp = url.searchParams.get('tryOutListen');
  const userId = url.searchParams.get('userid');

  const referer =
    request.headers.get('referer') ??
    getOriginReferer(request, '/')?.toString();

  const isBotUserAgent = isbot(request.headers.get('user-agent'));

  const headers = new Headers();

  if (tryListenApp) {
    headers.append(
      'Set-Cookie',
      await TryOutListenCookieJar.serialize(true, {
        expires: expireCookieIn365days(),
        domain: '.iheart.com',
      }),
      // `${PinToListenCookie}=true; path=/; domain=${cookieDomain}; secure; samesite=lax; expires=${expireCookieIn365days().toUTCString()};`,
    );
  }

  // UXD has asked us to remove the userEducationModal for public beta, but we might need to reinstate it for MVP launch
  // setting this to false so that the modal never shows up, but keeping code for future use
  // IHRWEB-20358
  const showUserEducationModal = false;

  if (showUserEducationModal) {
    headers.append(
      'Set-Cookie',
      await ShowDialogCookieJar.serialize(
        { showUserEducationModal: false },
        { expires: expireCookieIn24hours() },
      ),
    );
  }

  const market = await time(
    'getMarketForRequest',
    getMarketForRequest(request, { user }),
  );

  const marketNamesById = await time('getMarketNamesById', getMarketsById());

  const orderedMarkets = await time(
    'getMarketNamesSorted',
    getOrderedMarkets(),
  );

  headers.append('Server-Timing', getHeaderField());
  headers.append('X-Market', `${market.marketId}`);

  const hints = getHints(request);

  return json(
    {
      ampClientConfig,
      appVersion: npm_package_version ?? '',
      authEvent,
      config,
      geolocation: getGeolocationForRequest(request),
      isBotUserAgent,
      locale,
      CONFIG_ENV,
      market,
      orderedMarkets,
      marketNamesById,
      referer,
      SHORT_COMMIT,
      showUserEducationModal,
      user,
      userId,
      // This block is what the new `useTheme` hook uses to determine the correct theme from browser
      // hints or the explicit theme that the user has chosen
      requestInfo: {
        hints: {
          ...hints,
          theme: (await getThemeFromRequest(request)) ?? hints.theme,
        },
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: await ThemeCookie.parse(request.headers.get('Cookie')),
        },
      },
    },
    {
      headers,
    },
  );
};

export const meta: ServerRuntimeMetaFunction<typeof loader> = ({ data }) => {
  const { config } = data ?? {};

  return [
    ...setBasicMetadata({
      title: METADATA_GLOBAL_TITLE,
      description: METADATA_GLOBAL_DESCRIPTION,
      keywords: METADATA_GLOBAL_KEYWORDS,
      image: METADATA_DEFAULT_IMAGE,
      type: METADATA_OPENGRAPH_TYPES.Website,
      card: METADATA_TWITTER_CARDS.Summary,
      url: 'https://listen.iheart.com', // TODO: app url generation should use a common function
    }),

    { content: core.colors['brand-red'], name: 'theme-color' },
    ...(config ?
      [
        config?.sdks?.facebook?.appId ?
          { content: config.sdks.facebook.appId, property: 'fb:app_id' }
        : null,
        config?.sdks?.facebook?.pages ?
          { content: config.sdks.facebook.pages, property: 'fb:pages' }
        : null,
        { content: config.app.appleId, name: 'twitter:app:id:iphone' },
        { content: config.app.appleId, name: 'twitter:app:id:ipad' },
        { content: config.app.appleId, name: 'al:ios:app_store_id' },
        {
          content: config.app.googlePlayId,
          name: 'twitter:app:id:googleplay',
        },
        { content: config.app.googlePlayId, name: 'al:android:package' },
      ].filter(isNotNil)
    : []),
  ];
};

const FullScreenStorage = createWebStorage({
  seed: {
    isFullScreen: false,
  },
  prefix: 'player:fullscreen.',
  type: 'session',
});

export default memo(function App() {
  const {
    ampClientConfig,
    authEvent,
    locale,
    config,
    user,
    userId,
    showUserEducationModal,
    referer,
  } = useLoaderData<typeof loader>();
  const authEventRef = useRef<string>();

  const { visitNum } = useVisitCount();

  const analytics = useAnalytics();

  useTrackVisibilityChange(analytics);

  const { regGateState, onAnalyticsRegGateExit } = useRegGateEvent();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const loginToken = searchParams.get('loginToken');

  // On initial load of the root, if `loginToken` is present in the URL,
  // forward the user to Account to authenticate with the short live token
  useEffect(() => {
    if (loginToken) {
      navigate(`/login/auth?loginToken=${loginToken}`);
    }
  }, [loginToken, navigate]);

  if (isNotBlank(authEvent) && authEventRef.current !== authEvent.type) {
    authEventRef.current = authEvent.type;
    analytics.track(authEvent);
  } else if (!isNotBlank(authEvent)) {
    authEventRef.current = '';
  }

  useEffect(() => {
    if (regGateState.get('trigger') && isNotBlank(authEvent)) {
      onAnalyticsRegGateExit(
        regGateState.get('trigger'),
        authEvent.data?.authtype,
      );
    } else if (regGateState.get('trigger')) {
      onAnalyticsRegGateExit(regGateState.get('trigger'));
    }
  }, []);

  useEffect(() => {
    // This is so we send the user's profileId to Glassbox on initial load of the Polaris app
    // This will appear as a "Custom Event" in a user's recorded session within Glassbox
    if (window._detector?.trigger3rdPartyMap) {
      window._detector?.trigger3rdPartyMap({
        // We attempt to use the `userId` which is the id from the query params,
        // which is the profileId from the user's Legacy session, which is preferable.
        // If the `userId` query param is not present, use the loader's user object to grab the profileId.
        profileId: userId ?? user.profileId,
      });
    }
  }, []);

  // Get the user's timezone and store it in a cookie. This is used for LiveProfile, and it's nice
  // to be able to have it server-side
  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    globalThis.window.document.cookie = `tz=${tz}; max-age=31536000`;
  }, []);

  /**
   * -----------------------------------------------------------------------------------------------
   * State variables to control FullScreen Player show/hide
   * Lifting up to here because the ad slots need to know the full-screen status so that
   * 1) We don't render display ads when the FSP is open
   * 2) We don't double-render companion ads (once in the Nav Ad slot and once in FSP)
   *
   * The FullScreenContext.Provider was extracted as its own component and exported from Companion.
   * Rendered below just above `<Layout />`, since that's where all our "stuff" lives,
   * ad slots included.
   *
   * FullScreenProvider uses a memoized version of this `useState`, so re-renders are not a problem
   *
   * Additionally, set up sessionStorage and querystring control for fullscreen state, so that it
   * 1) Persists across refreshes
   * 2) Links can be shared that go directly to Full Screen experience
   *
   * The session variable will be used if and only if there is **no** query param supplied, and if
   * a query param __is__ supplied, that value will persist into the session storage
   **/
  const pageName = useGetPageName();
  if (searchParams.has('fullscreen')) {
    FullScreenStorage.set(
      'isFullScreen',
      toBoolean(searchParams.get('fullscreen')),
    );
  }

  const [isFullScreen, setIsFullScreen] = useState(false);

  // Run once on mount to set the state variable from session storage
  // using `FullScreenStorage.get('isFullScreen')` directly in the `useState` initializer
  // did not always work...🤷🏽‍♂️
  useEffect(() => {
    setIsFullScreen(FullScreenStorage.get('isFullScreen'));
  }, []);

  // If the state variable changes, persist into session storage
  useEffect(() => {
    FullScreenStorage.set('isFullScreen', isFullScreen);
  }, [isFullScreen]);
  // -----------------------------------------------------------------------------------------------

  useMemo(() => {
    amp.setConfig(ampClientConfig);
  }, [ampClientConfig]);

  const displayAdsScriptsConfig: DisplayAdsScriptsConfig =
    useMemo<DisplayAdsScriptsConfig>(
      () => ({
        enabled: user.subscription?.type !== SubscriptionTypeEnum.PREMIUM,
        enabledBidders: config.ads.headerBidding.enabledBidders,
        sdks: config.sdks,
        privacyOptOut: user.privacy?.hasOptedOut ?? false,
      }),
      [config, user],
    );

  const playbackAdsScriptsConfig = useMemo(
    () => ({
      tritonScript: config.ads.customAds.tritonScript,
      usPrivacy: CCPAUserPrivacy(user.privacy?.usPrivacy ?? ''),
    }),
    [config, user],
  );

  const headerBiddingConfig: HeaderBiddingConfig =
    useMemo<HeaderBiddingConfig>(() => {
      return {
        enabledBidders: config.ads.headerBidding.enabledBidders,
        email: user?.email,
        emailHashes: user?.emailHashes,
        pubId: config.sdks.amazon?.pubId,
        privacyOptOut: user.privacy?.hasOptedOut ?? false,
      };
    }, [config, user]);

  return (
    <Document
      displayAdsScriptsConfig={displayAdsScriptsConfig}
      playbackAdsScriptsConfig={playbackAdsScriptsConfig}
    >
      <UserContext.Provider value={user}>
        <GoogleAdsProvider
          enabled={user.subscription?.type !== SubscriptionTypeEnum.PREMIUM}
          headerBiddingConfig={headerBiddingConfig}
        >
          <ConfigProvider value={config}>
            <AdsTargetingProvider visitNum={visitNum}>
              {lsid => (
                <PlaybackProvider
                  adsEnabled={
                    user.subscription?.type !== SubscriptionTypeEnum.PREMIUM
                  }
                  apiConfig={{
                    baseUrl: config.api.amp.clientEndpoint,
                    hostName: config.environment.hosts.listen,
                    profileId: user?.profileId,
                    sessionId: user?.sessionId,
                    userPrivacyOptOut: user?.privacy?.hasOptedOut,
                    locale,
                  }}
                  dfpInstanceId={config.ads.dfpInstanceId}
                  environment="listen"
                  lsid={lsid}
                  pageName={pageName}
                  podcastTritonTokenEnabled={
                    config.features?.podcastTritonTokenEnabled ?? false
                  }
                  subscriptionType={user.subscription?.type}
                >
                  <Analytics referer={referer} />
                  <ToastProvider insetY="10.4rem">
                    {/* Render FullScreenProvider here */}
                    <FullScreenProvider
                      isFullScreen={isFullScreen}
                      setIsFullScreen={setIsFullScreen}
                    >
                      <Layout showUserEducationDialog={showUserEducationModal}>
                        <Outlet />
                      </Layout>
                    </FullScreenProvider>
                  </ToastProvider>
                </PlaybackProvider>
              )}
            </AdsTargetingProvider>
          </ConfigProvider>
        </GoogleAdsProvider>
      </UserContext.Provider>
    </Document>
  );
});

export interface DocumentProps extends DocumentSharedProps {
  displayAdsScriptsConfig?: DisplayAdsScriptsConfig;
  playbackAdsScriptsConfig?: { tritonScript: string; usPrivacy: string };
  CONFIG_ENV?: string;
  SHORT_COMMIT?: string;
}

export const Document = ({
  children,
  displayAdsScriptsConfig,
  playbackAdsScriptsConfig,
  rootError,
}: DocumentProps) => {
  const { SHORT_COMMIT, CONFIG_ENV, appVersion } = useRootLoaderData();
  const clientStyleData = useContext(ClientStyleContext);
  const pageName = useGetPageName();
  const theme = useTheme(rootError);
  // set theme value from `useTheme` into a state variable that we can mutate based on the
  // StorageEvent that gets triggered in `/bridge`
  const [themeState, setThemeState] = useState<Theme>(theme);

  useAppOpenClose(analytics, 'listen', appVersion, pageName);

  // If the storage event key is `iheartradio-theme`, then JSON parse the `newValue` and pass it
  // to the state setter
  const storageEventHandler = (event: StorageEvent) => {
    // Set theme that was selected in web.account
    if (event.key === 'iheartradio-theme' && event.newValue) {
      try {
        setThemeState(JSON.parse(event.newValue) as Theme);
      } catch {
        setThemeState(event.newValue as Theme);
      }
    } else if (event.key === 'force-logout' && event.newValue) {
      // Refresh page if logout occurred on web.account
      window.location.replace('/logout');
    }
  };

  // Add an event listener for `storage` and call the handler defined above
  useEffect(() => {
    localStorage.removeItem('force-logout');
    window.addEventListener('storage', storageEventHandler);

    return () => {
      window.removeEventListener('storage', storageEventHandler);
    };
  }, []);

  // Only executed on client
  useEffect(() => {
    // Reset cache to re-apply global styles
    clientStyleData.reset();
  }, [clientStyleData, themeState]);

  return (
    <html lang="en">
      <head>
        <link href="https://i.iheart.com" rel="preconnect" />
        <meta charSet="utf-8" />
        <meta content="width=device-width,initial-scale=1" name="viewport" />
        <meta content="on" httpEquiv="x-dns-prefetch-control" />
        <meta content="yes" name="mobile-web-app-capable" />
        <meta content={METADATA_APP_NAME} property="og:site_name" />
        <meta content={METADATA_DOMAIN} name="twitter:domain" />
        <meta content={METADATA_TWITTER_HANDLE} name="twitter:creator" />
        <meta content={METADATA_TWITTER_HANDLE} name="twitter:site" />
        <meta content={METADATA_APP_NAME} name="twitter:app:name:iphone" />
        <meta content={METADATA_APP_NAME} name="twitter:app:name:ipad" />
        <meta content={METADATA_APP_NAME} name="twitter:app:name:googleplay" />
        <meta content={METADATA_APP_NAME} name="al:android:app_name" />
        <meta content={METADATA_APP_NAME} name="al:ios:app_name" />
        <Meta />
        <ClientHintCheck />
        <DynamicLinks />
        <link href="/favicon.ico" rel="shortcut icon" type="image/ico" />
        <link href={`/${METADATA_APPLE_TOUCH_ICON}`} rel="apple-touch-icon" />
        <link href={`/${METADATA_APPLE_TOUCH_ICON}`} rel="shortcut icon" />
        <Links />
        <style
          dangerouslySetInnerHTML={{ __html: clientStyleData.sheet }}
          id="stitches"
          suppressHydrationWarning
        />
        <script
          async
          id="_cls_detector"
          src={
            CONFIG_ENV === 'production' ?
              'https://cdn.gbqofs.com/iheartmedia/web.listen/p/detector-dom.min.js'
            : 'https://cdn.gbqofs.com/iheartmedia/web.listen/u/detector-dom.min.js'
          }
        />
        {displayAdsScriptsConfig ?
          <DisplayAdsScripts
            enabled={displayAdsScriptsConfig.enabled}
            enabledBidders={displayAdsScriptsConfig.enabledBidders}
            language={getBrowserLanguage() ?? 'en'}
            privacyOptOut={displayAdsScriptsConfig.privacyOptOut}
            sdks={displayAdsScriptsConfig.sdks}
          />
        : null}
        {playbackAdsScriptsConfig ?
          <PlaybackAdsScripts
            tritonScript={playbackAdsScriptsConfig.tritonScript}
            usPrivacy={playbackAdsScriptsConfig.usPrivacy}
          />
        : null}
      </head>
      <ThemeContext.Provider value={themeState}>
        <body data-theme={themeState} data-version={SHORT_COMMIT}>
          {children}
          <ScrollRestoration />
          <Scripts />
        </body>
      </ThemeContext.Provider>
    </html>
  );
};

export const ErrorBoundary = () => <AppErrorBoundary document={Document} />;
