import type { Playback } from '@iheartradio/web.playback';
import { isUndefined } from '@iheartradio/web.utilities';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { useConfig } from '../config';
import { useUser } from '../user';
import { AdsTargetingEmitter } from './ads-targeting-emitter';

export type AdsTargetingContextValue = Playback.Targeting | null;

export type AdsProviderProps = {
  visitNum?: number;
  children(lsid: string): ReactNode;
};

export const AdsTargetingProvider: React.FC<AdsProviderProps> = ({
  visitNum,
  children,
}: AdsProviderProps) => {
  const config = useConfig();
  const user = useUser();

  const isBrowser = !isUndefined(globalThis.window?.document);

  // This is where we hook the `AdsTargetingEmitter` into the React lifecycle
  // We want this is a `useMemo` so it runs sooner, but only in the browser
  useMemo(async () => {
    if (isBrowser && !AdsTargetingEmitter.initialized) {
      await AdsTargetingEmitter.initialize(config, user, visitNum);
    }
  }, [config, user, visitNum, isBrowser]);

  return <>{children(AdsTargetingEmitter.getLsid())}</>;
};
