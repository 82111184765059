import { breakpointSizes } from '@iheartradio/web.accomplice';
import { type SliderProps, Slider } from '@iheartradio/web.accomplice/slider';
// import * as AccompliceTooltip from '@iheartradio/web.accomplice/tooltip';
import {
  Button,
  Grid,
  Text,
  Tooltip,
  VolumeHighIcon,
  VolumeLowIcon,
  VolumeOffIcon,
} from '@iheartradio/web.companion';

interface PlayerVolumeProps {
  isDisabled?: SliderProps['isDisabled'];
  onChangeEnd?: SliderProps['onChangeEnd'];
  onMute?: JSX.IntrinsicElements['button']['onClick'];
  muted?: boolean;
  volume: number;
}

// NOTE: This should be moved back to the component library once all the needed components have been refactored
export function PlayerVolume(props: PlayerVolumeProps) {
  const { isDisabled, muted, volume, onMute, onChangeEnd } = props;

  return (
    <Grid
      display={{
        [`@container (min-width: ${breakpointSizes.xsmall}px)`]: 'none',
        [`@container (min-width: ${breakpointSizes.large}px)`]: 'grid',
      }}
      gridArea="volume"
      width="100%"
    >
      <Grid
        alignItems="center"
        columnGap="$4"
        padding="$0 $8"
        templateColumns="min-content 1fr"
        width="16rem"
      >
        <Tooltip
          side="top"
          trigger={
            <Button
              color={{ dark: 'white', light: 'gray' }}
              kind="tertiary"
              onClick={volume > 0 ? onMute : undefined}
              size="icon"
            >
              {muted || volume === 0 ?
                <VolumeOffIcon size={32} />
              : null}
              {!muted && volume > 0 && volume <= 50 && (
                <VolumeLowIcon size={32} />
              )}
              {!muted && volume > 50 && <VolumeHighIcon size={32} />}
            </Button>
          }
        >
          <Text
            color={{
              dark: '$brand-black',
              light: '$brand-white',
            }}
            kind="caption-3"
          >
            {muted ? 'Unmute' : 'Mute'}
          </Text>
        </Tooltip>
        {/* <AccompliceTooltip.TooltipTrigger closeDelay={0} delay={500}> */}
        {/* <AccompliceTooltip.TooltipTriggerElement> */}
        <Slider
          aria-label="Adjust Volume"
          defaultValue={volume}
          isDisabled={isDisabled}
          maxValue={100}
          minValue={0}
          onChangeEnd={onChangeEnd}
        />
        {/* </AccompliceTooltip.TooltipTriggerElement> */}
        {/* <AccompliceTooltip.Tooltip placement="top"> */}
        {/* Adjust Volume */}
        {/* </AccompliceTooltip.Tooltip> */}
        {/* </AccompliceTooltip.TooltipTrigger> */}
      </Grid>
    </Grid>
  );
}
