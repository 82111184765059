import { Flex } from '@iheartradio/web.accomplice/flex';
import {
  Box,
  BrowseIcon,
  Button,
  CaretDownIcon,
  HomeIcon,
  Link,
  LoginIcon,
  LogotypeBeta,
  media,
  Navigation,
  Popover,
  Separator,
  SettingsIcon,
  Spacer,
  Text,
  Theme,
  useMediaQuery,
  useTheme,
  YourLibraryIcon,
} from '@iheartradio/web.companion';
import { NavLink, useLocation } from '@remix-run/react';
import { $path } from 'remix-routes';
import { ClientOnly } from 'remix-utils/client-only';

import { NavAd } from '~app/ads/display/nav-ad';
import { useClick } from '~app/analytics/use-click';
import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { useUser } from '~app/contexts/user';
import type { AppsFlyerOffset } from '~app/hooks/apps-flyer/use-apps-flyer';
import { useDismissiblePopover } from '~app/hooks/use-dismissible-popover/use-dismissible-popover';
import { useGetPageName } from '~app/hooks/use-get-page-name';
import { REG_GATE_TRIGGER_TYPES, Routes } from '~app/utilities/constants';

const DesktopPopover = () => {
  const user = useUser();

  const theme = useTheme();
  const pageName = useGetPageName();

  const { onAnalyticsClick } = useClick();
  const reverseTheme = theme === Theme.Dark ? Theme.Light : Theme.Dark;

  const { popoverRef, dismissPopover } = useDismissiblePopover(10_000);

  return (
    <ClientOnly>
      {() => (
        <Box>
          <Spacer left="$24" top="$16">
            <Button
              as="a"
              css={{ background: '$gradient-bright-blue' }}
              data-test="share-feedback-button"
              href={`https://surveys.segmanta.com/927u43?userId=${user?.profileId}`}
              id="user-education-feedback"
              kind="primary"
              onClick={() => {
                onAnalyticsClick(pageName, 'navigation|share-feedback');
              }}
              size={{ '@initial': 'small', '@large': 'large' }}
              target="_blank"
            >
              Share feedback
            </Button>
          </Spacer>
          {!dismissPopover && (
            <Box
              css={{
                transform: 'translateY(-10px)',
              }}
              paddingLeft="$16"
              ref={popoverRef}
            >
              <CaretDownIcon
                css={{
                  transform: 'rotateX(180deg) translate(20px, -14px)',
                }}
                fill={{
                  dark: 'brand-white',
                  light: 'gray-600',
                }}
              />
              <Box
                backgroundColor={{ dark: '$gray-600', light: '$brand-white' }}
                borderRadius="$6"
                boxShadow="$elevation-4"
                data-theme={reverseTheme}
                maxWidth={{
                  '@initial': '80vw',
                  '@small': '60vw',
                  '@medium': '28rem',
                }}
                outline="none"
                padding="$16"
                zIndex="$10"
              >
                <Text kind="body-3">
                  Your feedback is essential for us to improve our site.
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </ClientOnly>
  );
};

const MobilePopover = () => {
  const user = useUser();
  const { popoverRef, dismissPopover } = useDismissiblePopover(10_000);
  const pageName = useGetPageName();

  const { onAnalyticsClick } = useClick();

  return (
    <ClientOnly>
      {() => (
        <Popover
          open={!dismissPopover}
          ref={popoverRef}
          trigger={
            <Button
              as="a"
              css={{ background: '$gradient-bright-blue' }}
              data-test="share-feedback-button"
              href={`https://surveys.segmanta.com/927u43?userId=${user?.profileId}`}
              id="user-education-feedback"
              kind="primary"
              onClick={() => {
                onAnalyticsClick(pageName, 'navigation|share-feedback');
              }}
              size={{ '@initial': 'small', '@large': 'large' }}
              target="_blank"
            >
              Share feedback
            </Button>
          }
          zIndex="$9"
        >
          <Text kind="body-3">
            Your feedback is essential for us to improve our site.
          </Text>
        </Popover>
      )}
    </ClientOnly>
  );
};

interface NavProps {
  appsFlyerOffset: AppsFlyerOffset;
}

export function Nav({ appsFlyerOffset }: NavProps) {
  const user = useUser();
  const { pathname } = useLocation();
  const { onAnalyticsRegGateOpen } = useRegGateEvent();
  const isLargeScreen = useMediaQuery(media.large);

  return (
    <>
      <Navigation
        css={{ zIndex: '$2', '@large': { paddingBottom: '9.1rem !important' } }}
        data-has-button
        data-test="navigation-wrapper"
        key={`${user?.isAnonymous ? 'anon' : 'auth'}-nav`}
      >
        <Navigation.Header css={{ marginTop: appsFlyerOffset }}>
          <Link as={NavLink} to={$path('/')}>
            <LogotypeBeta
              aria-label="iHeart Header Logotype"
              media="mobile"
              size={24}
            />
          </Link>
          {!isLargeScreen ?
            <MobilePopover />
          : null}
        </Navigation.Header>
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="flex-start"
          width="100%"
        >
          <Navigation.Logo>
            <Link as={NavLink} to={$path('/')}>
              <LogotypeBeta
                aria-label="iHeart Navigation Logotype"
                media="desktop"
                size={24}
              />
            </Link>
          </Navigation.Logo>
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Flex
              direction="column"
              id="user-education"
              justifyContent="flex-start"
            >
              <Navigation.List>
                <Navigation.Item
                  active={pathname === $path('/')}
                  as={NavLink}
                  data-test="home"
                  to={$path('/')}
                >
                  <HomeIcon />
                  Home
                </Navigation.Item>
                <Navigation.Item
                  active={pathname.startsWith(Routes.Browse.Root)}
                  as={NavLink}
                  data-test="browse"
                  id="user-education-browse"
                  to={$path('/browse/live')}
                >
                  <BrowseIcon />
                  Browse
                </Navigation.Item>
                <Navigation.Item
                  active={pathname.startsWith('/library')}
                  as={NavLink}
                  data-test="library"
                  id="user-education-library"
                  to={$path('/library/live')}
                >
                  <YourLibraryIcon />
                  Library
                </Navigation.Item>
                <Separator
                  display={{ '@initial': 'none', '@large': 'inline-block' }}
                />
                {!user || user?.isAnonymous ?
                  <Navigation.Item
                    as={NavLink}
                    data-test="login"
                    onClick={() =>
                      onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.LOG_IN)
                    }
                    to={$path('/login/:action?', { action: 'auth' })}
                  >
                    <LoginIcon />
                    Log In
                  </Navigation.Item>
                : <Box width="100%">
                    <Navigation.Item
                      as={NavLink}
                      data-test="settings"
                      target="_blank"
                      to={$path('/settings')}
                    >
                      <SettingsIcon />
                      Settings
                    </Navigation.Item>
                  </Box>
                }
              </Navigation.List>
              <DesktopPopover />
            </Flex>
            <Spacer bottom="$24" left="$8">
              <NavAd />
            </Spacer>
          </Flex>
        </Flex>
      </Navigation>
    </>
  );
}
